@import "src/ui/colors.scss";
@import "src/ui/variables.scss";
@import "src/ui/media.scss";

.home {
  margin: 0 0 0 0;

  section {
    position: relative;
  }

  .home-top {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    @include desktop {
      flex-direction: row;
    }
  }

  .calendar-type {
    border-bottom: 1px solid $light-grey;

    ul {
      // margin: 28px 0 30px -40px;
    }
  }

  .home-image {
    order: 1;
    margin: 0 -16px;

    @include landscape {
      margin: 0 -25px;
    }

    @include desktop {
      position: relative;
      margin: 0;
      top: 0;
      width: calc(100% - 100px);
      display: inline-block;
      order: 2;
    }
  }

  .home-intro {
    z-index: 20;
    position: relative;
    order: 2;
    margin: 40px 0 0 0;

    @include desktop {
      margin: 0;
      top: 0;
      width: 100px;
      display: inline-block;
      vertical-align: top;
    }
  }

  .avatar {
    width: 120px;
    height: 120px;
    border-radius: 60px;
    overflow: hidden;
    border: 7px solid $white;
    right: -10px;
    margin: 0 0 0 -60px;
    position: absolute;
    top: -120px;

    @include desktop {
      right: 10px;
      bottom: -60px;
      top: auto;
    }

    .image,
    img {
      border-radius: 50%;
    }
  }

  .guidings {
    max-width: 600px;
  }

  .home-intro-inner {
    background-color: $white;
    margin: 20px 0 0 0;
    text-align: center;
    position: relative;

    @include desktop {
      text-align: left;
      margin: 30px 0 0 0;
      padding: 16px 25px 30px 0;
      width: 500px;
    }

    p {
      font-size: $standard-font-size;
    }
  }

  .guidings-wrapper {
    margin: 60px 0 0 0;
    // max-width: 600px;

    @include desktop {
      margin: 60px 0 60px 100px;
    }
  }
}

.swedish .home {
  .message {
    margin: 0 -16px;
    border-radius: 0;
    border: 1px solid #c5e1ff;
    border-left: 0;
    border-right: 0;
    padding: 24px 16px;

    @include landscape {
      margin: 0 -25px;
    }

    @include tablet {
      margin: 0;
      border-radius: 10px;
      border: 1px solid #c5e1ff;
    }
  }

  .message-error {
    background-color: #ffeded;
    border: 1px solid #d17777;
    border-left: 1px solid #d17777;
    border-right: 1px solid #d17777;
  }

  .message-info {
    // School
    // &.message {
    //   display: none;
    // }

    p:first-child {
      margin: 0 0 20px 0;

      b {
        margin: 0 4px 0 0;
      }
    }

    span {
      display: inline-block;
      width: 140px;

      b {
        margin: 0 6px 0 0;
      }
    }

    li {
      line-height: 1.4;
      margin: 6px 0 0 0;
    }
  }

  .guidings,
  .message {
    @include tablet {
      display: inline-block;
      width: 50%;
      vertical-align: top;

      // School
      // display: block;
      // width: 100%;
    }
  }

  .guidings {
    margin: 60px 0 60px 0;

    @include tablet {
      margin: 0;
      padding: 0 0 0 30px;

      // School
      // padding: 0;
      // margin: 30px 0 0 0;
    }

    @include desktop {
      padding: 0 0 0 50px;

      // School
      // padding: 0;
      // margin: 40px 0 0 0;
    }

    @include desktop-plus {
      padding: 15px 0 0 50px;

      // School
      // padding: 0;
      // margin: 40px 0 0 0;
    }
  }

  .text-large {
    & > p:first-child {
      font-size: $large-font-size;
    }
  }
}
