@import 'src/ui/colors.scss';
@import 'src/ui/variables.scss';
@import 'src/ui/media.scss';

.message {
  padding: 10px 10px 10px 48px;
  padding: 16px 16px;
  position: relative;
  border: 1px solid $light-blue;
  background-color: $lighter-blue;
  border-radius: 4px;
  font-size: 15px;

  @include tablet {
    padding: 16px 25px;
  }

  h2 {
      margin: 0 0 10px 0;
  }


  // font-size: $extra-small-font-size;
  //
  // @include tablet {
  //   font-size: $small-font-size;
  // }

  .icon-wrapper {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 10px;
      left: 14px;
  }
}

.message-info {

  svg {
      fill: $dark-blue;
      fill: $print;
  }
}
