@import "src/ui/variables.scss";
@import "src/ui/media.scss";

.no-match {

    article {
        max-width: 500px;
        margin: 50px auto;
        text-align: center;

        @include tablet {
            margin: 100px auto;
        }

        p {
            margin: 30px 0 50px 0;
            font-weight: 300;
            font-size: $standard-font-size;

            @include tablet {
                font-size: $large-font-size;
            }
        }
    }
}
