@import "src/ui/media.scss";
@import "src/ui/variables.scss";
@import "src/ui/colors.scss";
@import "src/ui/mixins.scss";

$logo-width: 120px;

.footer-wrapper {
  background-color: $pale-grey;
  border-top: 1px solid $light-grey;
  padding: 40px 0 20px 0;

  font-size: $extra-small-font-size;

  @include tablet {
    padding: 40px 0 80px 0;
    font-size: $small-font-size;
  }

  footer {
    @include page-padding();
    max-width: $desktop-plus;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-content: stretch;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
    }
  }

  .footer-mya,
  .footer-contact {
    order: 1;
    width: 100%;

    @include tablet {
      width: calc(50% - 60px);
      display: inline-block;
      vertical-align: top;
    }
  }

  .footer-contact {
    order: 2;

    @include tablet {
      order: 3;
      text-align: right;
    }
  }

  .footer-logo {
    order: 3;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    font-size: 12px;
    display: block;
    margin: 30px 0 0 0;

    @include tablet {
      order: 2;
      width: $logo-width;
      margin: 0;
    }
  }

  li {
    margin: 5px 0;
    &:last-child {
      margin: 5px 0 0 0;
    }
  }

  .olinks {
    display: none;

    @include tablet {
      display: inline-block;
    }
  }

  .gdpr {
    @include page-padding();
    max-width: $desktop-plus;
    margin: 40px auto 0 auto;
    font-size: 10px;
    opacity: 0.7;

    @include tablet {
      font-size: 12px;
      margin: 60px auto 0 auto;
    }
  }

  .gdpr-inner {
    max-width: $tablet;
  }
}

.footer-mya {
  ul:first-child {
    display: inline-block;
    width: calc(100% - 52px);

    @include tablet {
      width: 100%;
    }
  }

  .olandsguide-logo {
    margin: 0;

    @include tablet {
      margin: 14px 0 0 0;
    }
  }
}
