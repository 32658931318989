@import "src/ui/media.scss";
@import "src/ui/colors.scss";
@import "src/ui/variables.scss";

body {
  font-size: 14px;
  color: $black;
  font-weight: 400;
  line-height: $line-height-standard;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif;
}

.indie {
  font-family: "Indie", sans-serif;
}

p {
  font-size: 14px;
  line-height: $line-height-standard;
  margin: 14px 0;

  &:first-child {
    margin: 0 0 14px 0;
  }
}

.upper {
  text-transform: uppercase;
}

strong,
p.strong {
  font-weight: 700;
}

.semi-bold {
  font-weight: 600;
}

.light,
p.light {
  font-weight: 300;
}

cite,
em,
i,
.italic {
  font-style: italic;
}

.center {
  text-align: center;
}

.nowrap {
  white-space: nowrap;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: $line-height-medium;
  margin: 30px 0 14px 0;
  font-weight: 300;

  &:first-child {
    margin: 0 0 14px 0;
  }

  @include tablet {
    line-height: $line-height-standard;
  }
}

h1,
h2,
h3 {
  font-weight: 300;
}

h4,
h5 {
  &:first-child {
    margin: 0 0 10px 0;
  }
}

h1 {
  letter-spacing: -0.05rem;
  font-size: 28px;

  @include tablet {
    font-size: 32px;
  }
}

h2 {
  font-size: 24px;

  @include tablet {
    font-size: 26px;
  }
}

h3 {
  font-size: 20px;

  @include tablet {
    font-size: 22px;
  }
}

h4,
h5 {
  font-weight: 700;
  font-size: 18px;
}

h5 {
  font-size: 14px;
  text-transform: uppercase;
}

.small {
  font-size: $small-font-size;
}

h1 + p,
h2 + p,
h3 + p,
h4 + p,
h5 + p {
  margin: 10px 0 14px 0;
}
