@import "src/ui/media.scss";

.images-page {
  .image-wrapper {
    margin: 0 -16px;

    @include landscape {
      margin: 0 -25px;
    }

    @include tablet {
      margin: 0;
    }
  }

  .school-link {
    display: none;
  }

  .calendar {
    .stories,
    .stone,
    .fossil,
    .bone,
    .byerum {
      span {
        font-weight: normal;
      }

      .bg {
        background-color: #fff;
      }
    }

    .date-notifier {
      display: none;
    }
  }

  .calendar-type {
    .pictures-extra {
      & > span {
        display: inline-block;
        color: #373d3f;
        width: 120px;
        margin: 0;
        vertical-align: top;

        &:last-child {
          width: calc(100% - 120px);
        }
      }
    }
  }

  .pictures-time {
    display: inline-block;
    width: 50px;
  }

  .pictures-additional {
    position: relative;
    padding: 0 0 0 15px;
    display: none !important;

    @include landscape {
      padding: 0 0 0 20px;
    }
  }

  .calendar-type .date-notifier {
    right: auto;
    left: 0;
    top: 5px;
    border: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;

    @include landscape {
      left: 6px;
      top: 7px;
    }
  }
}
