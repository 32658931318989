@import "src/ui/media.scss";
@import "src/ui/variables.scss";
@import "src/ui/colors.scss";

$cell-height: 28px;
$cell-height-tablet: 32px;
$cell-height-desktop-plus: 36px;

.dates-wrapper {
  @include desktop-plus {
    top: 100px;
    transform: translate3d(0, 0, 0);

    &.fixed {
      position: fixed;
    }
  }
}

.date-notifier {
  background-color: #ff7a7a;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  position: absolute;
  top: -1px;
  border: 3px solid #fff;
  display: none;
}

.dates {
  margin: 0 0 50px 0;

  .month-name {
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 10px 0;
  }

  .star {
    background-color: #fff;
    display: inline-block;
    padding: 0px 0 0px 3px;
    line-height: 19px;
    font-size: 12px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    position: absolute;
    top: -1px;
    right: 5px;
    display: none;
  }

  .day-numbers,
  .day-names {
    font-size: $small-font-size;

    li {
      display: inline-block;
      position: relative;
      width: 14.28%;
      text-align: center;
      height: $cell-height;
      line-height: $cell-height;

      @include tablet {
        width: 36px;
        height: $cell-height-tablet;
        line-height: $cell-height-tablet;
      }

      @include desktop {
        width: 70px;
      }

      @include desktop-plus {
        height: $cell-height-desktop-plus;
        line-height: $cell-height-desktop-plus;
      }
    }
  }

  .day-names {
    margin: 0 0 6px 0;
    padding: 4px 0;
    border-bottom: 1px solid $light-grey;
    border-top: 1px solid $light-grey;
  }

  .day-numbers {
    .inactive {
      font-weight: normal;
      color: #999;
    }
  }
}

.calendar .twin {
  div.bg-church {
    background-color: $green;
    z-index: 4;
    mix-blend-mode: multiply;
    margin-left: calc(-16px + 5px);
  }
  div.bg-images {
    z-index: 3;
    margin-left: calc(-16px - 5px);
  }
}

.outer-diamond {
  box-shadow: 0 0px 6px 3px rgba(199, 225, 255, 0.8);
  transition: box-shadow 300ms ease;
}

.link:hover {
  .outer-diamond {
    box-shadow: 0 0px 6px 3px rgba(199, 225, 255, 0);
  }
}

.inner-diamond,
.outer-diamond {
  border: 1px solid $black;
  width: 28px;
  height: 28px;
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: rotate(45deg);
  margin: 0 0 0 -14px;

  @include tablet {
    width: 32px;
    height: 32px;
    margin: 0 0 0 -16px;
  }

  @include desktop-plus {
    top: 2px;
  }
}

.inner-diamond {
  width: 22px;
  height: 22px;
  margin: 0 0 0 -11px;
  border: 1px solid $black;
  top: 3px;

  @include tablet {
    width: 26px;
    height: 26px;
    margin: 0 0 0 -13px;
  }

  @include desktop-plus {
    top: 5px;
  }
}
