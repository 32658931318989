$black: #2e2d2e;
$pure-black: #000;
$white: #fff;

$print: #47525d;
$indicator-grey: #d0dae4;
$light-grey: #e5e5e5;
$pale-grey: #f7f7f7;

$blue: #78b9ff;

$link: #6796c7;
$link: #3880cc;
$link-hover: darken($link, 10%);

$calendar-pictures: lighten(#f99d97, 5%);
$calendar-stone: #e6c9e4;
$calendar-quarry: #a7deae;
$calendar-bone: lighten(#459fff, 15%);

$purple: #e6c9e4;
// $blue: lighten(#459fff, 15%);
$blue: #c5e1ff;
$red: lighten(#f99d97, 5%);
$red: #ffcdc9;
$green: #a7deae;
$beige: #f1e6d7;

$lighter-blue: lighten($blue, 7%);
$light-blue: lighten($blue, 10%);
$dark-blue: darken($blue, 10%);
