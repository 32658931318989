@import "src/ui/colors.scss";
@import "src/ui/variables.scss";
@import "src/ui/media.scss";

.shines-page {
  .pictures-time {
    display: inline-block;
    width: 50px;
  }

  .image-wrapper {
    margin: 0 -16px;

    @include landscape {
      margin: 0 -25px;
    }

    @include tablet {
      margin: 0;
    }
  }

  .school-link {
    display: none;
  }

  .calendar {
    .date-notifier {
      display: none;
    }

    .stories,
    .church,
    .stone,
    .fossil,
    .images,
    .byerum,
    .bone {
      span {
        font-weight: normal;
      }

      .bg {
        background-color: #fff;
      }
    }
  }

  .calendar .twin {
    .date {
      font-weight: bold;
    }

    div.bg-church {
      background-color: $purple;
      margin-left: -14px;

      @include tablet {
        margin-left: -16px;
      }

      @include desktop-plus {
        margin-left: -18px;
      }
    }
  }
}

// .shines-page {
//     margin: 0 auto 0 auto;

//     @include tablet {
//         margin: 0 auto 0 auto;
//     };

//     .shines-inner {
//         max-width: $desktop;
//         margin: 40px auto 0 auto;
//     }

//     .text-right {
//         font-size: $extra-small-font-size;
//     }

//     dd {
//         margin: 0 0 6px 0;
//     }

//     .image-wrapper {
//         margin: 0 -16px;

//         @include landscape {
//             margin: 0 -25px;
//         }

//         @include tablet {
//             margin: 0;
//         }
//     }

//     .morning,
//     .afternoon {
//         margin: 40px 0 0 0;

//         h4 {
//             margin: 20px 0 14px 0;
//         }

//         @include tablet {
//             display: inline-block;
//             width: 50%;
//             vertical-align: top;
//         }

//     }

//     .morning {
//         padding: 0 0 40px 0;
//         border-bottom: 1px solid #e5e5e5;

//         @include tablet {
//             border-bottom: none;
//             padding: 0 30px 0 0;
//         }

//         @include desktop {
//             padding: 0 50px 0 0;
//         }
//     }

//     .afternoon {
//         @include tablet {
//             padding: 0 0 0 30px;
//         }

//         @include desktop {
//             padding: 0 0 0 50px;
//         }
//     }

//     .ingress {

//         @include tablet {
//             min-height: 85px;
//         }

//         @include desktop {
//             min-height: 65px;
//         }
//     }

//     .body {

//         padding: 14px 0 20px 0;

//         @include tablet {
//             padding: 0;
//             min-height: 210px;
//         }

//         @include desktop {
//             min-height: 170px;
//         }
//     }
// }
