@import "src/ui/media.scss";

.stone-page {
  .day-label {
    margin: 0 12px 0 0;
  }

  .pictures-time {
    display: inline-block;
    width: 50px;
  }

  .image-wrapper-inner {
    max-width: 1024px;
    margin: 0 auto;
  }

  .image-wrapper {
    margin: 0 -16px;

    @include landscape {
      margin: 0 -25px;
    }

    @include tablet {
      margin: 0;
    }
  }

  .school-link {
    display: none;
  }

  .calendar {
    .date-notifier {
      display: none;
    }

    .shines,
    .church,
    .stories,
    .fossil,
    .images,
    .byerum,
    .bone {
      span {
        font-weight: normal;
      }

      .bg {
        background-color: #fff;
      }
    }
  }
}
