@import "src/ui/media.scss";
@import "src/ui/variables.scss";

@mixin ellipsis() {
    overflow: hidden;
    text-overflow: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}

@mixin half() {
    vertical-align: top;
    width: 50%;
    display: inline-block;
}

@mixin page-padding() {
    padding: 0 16px;

    @include landscape {
        padding: 0 25px;
    }
}
