@import "src/ui/media.scss";
@import "src/ui/colors.scss";

.school {
  // min-height: 200px;
  margin: 0 0 20px 0;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 26px -11px #5c6269;
  // padding: 16px;
  // font-size: 15px;
  font-weight: 500;

  p {
    // font-size: 15px;
  }

  .school-info {
    margin: 24px 0 0 0;
  }

  .date-tag {
    padding: 5px 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: -1px;
    right: -1px;
    background-color: black;
    color: white;
    font-weight: 600;
    z-index: 10;
  }

  .school-top {
    margin: 16px 0 6px 0;

    @include landscape {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    i {
      font-size: 40px;
      line-height: 1;
      font-family: "Indie", sans-serif;
      display: block;
      margin: 0 0 8px 0;

      @include landscape {
        display: inline;
      }
    }

    span {
      // font-family: "Montserrat", sans-serif;
      // font-size: 1rem;
      font-weight: bold;
    }
  }

  .school-content {
    padding: 5px 16px 30px 16px;
    // border: 1px solid $light-blue;
    background-color: $lighter-blue;

    @include tablet {
      padding: 5px 25px 30px 25px;
    }
  }

  dt,
  dd {
    display: inline-block;
    vertical-align: top;
  }

  dt {
    width: 90px;
    font-weight: 700;
  }

  dd {
    width: calc(100% - 90px);
  }

  .flex {
    @include tablet {
      display: flex;
    }

    .school-content {
      @include tablet {
        max-width: 650px;
        flex: 0 0 65%;
        order: 1;
      }
    }

    .school-image {
      @include tablet {
        background-image: url("./school_1000x750.jpg");
        background-size: cover;
        background-position: 30% 50%;
        flex: 1 0 35%;
        order: 2;
      }

      @include desktop-plus {
        background-position: 0% 50%;
      }
    }
  }

  #school-anchor {
    position: relative;
    top: -100px;
    visibility: hidden;
  }
}
