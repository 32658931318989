@import 'src/ui/colors.scss';
@import 'src/ui/variables.scss';

.image {
    width: 100%;
    background-color: $light-grey;
    position: relative;
    margin: 0;

    img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        will-change: opacity;
        transition: opacity $timer-standard ease;
    }

    img.loaded {
        opacity: 1;
    }

    &.round {

      border-radius: 50%;

      img {
        border-radius: 50%;
      }

    }
}
