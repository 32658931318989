@import "src/ui/variables.scss";
@import "src/ui/colors.scss";
@import "src/ui/media.scss";

.mobile-menu {
    position: fixed;
    top: 81px;
    left: 0;
    transition: transform $timer-standard ease;
    transform: translate3d(100vw, 0, 0);
    width: 70vw;
    min-height: 100vh;
    background-color: $white;
    z-index: 100;

    .languages {
        padding: 0 20px;
        margin: 6px 0;

        .link {
            width: 33.33%;
            text-align: center;
            padding: 14px 0;
            display: inline-block;
            position: relative;

            @include landscape {
                width: 50px;
            }

            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }
        }
    }

    // .link.shines {
    //     display: none;
    // }
}

.english,
.german {
    .link.senses,
    .link.shines {
        display: none;
    }
}

.mobile-link-lang-swedish,
.mobile-link-lang-english,
.mobile-link-lang-german {
    div {
        transition: transform $timer-standard ease;
        will-change: transform;
        transform: scale(0);
        width: 16px;
        height: 2px;
        position: absolute;
        bottom: 14px;
        left: 50%;
        margin: 0 0 0 -8px;
        background-color: $link;
    }

    svg {
        transition: filter $timer-standard ease;
        will-change: filter;
        filter: grayscale(100%)brightness(80%);
    }

    &.active {
        div {
            transform: scale(1);
        }

        svg {
            filter: grayscale(0)brightness(100%);
        }
    }
}

.mobile-link-lang-swedish div {
    left: 13px;
}

.mobile-link-lang-german div {
    left: auto;
    right: 5px;
}


.mobile-menu-links {
    margin: 10px 0;

    .link {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        color: $print;
        padding: 12px 16px;
        display: inline-block;
        width: 100%;
        border-left: 4px solid transparent;
        margin: 0 0 4px 0;

        &.active {
            background-color: $lighter-blue;
            color: $black;
            border-left: 4px solid $blue;
        }
    }
}

.has-open-menu {
    .mobile-menu {
        transform: translate3d(30vw, 0, 0);
    }
}
