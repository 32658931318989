@import "src/ui/media.scss";

.stories-page {
  .pictures-time {
    display: inline-block;
    width: 50px;
  }

  .image-wrapper {
    margin: 0 -16px;

    @include landscape {
      margin: 0 -25px;
    }

    @include tablet {
      margin: 0;
    }
  }

  .school-link {
    display: none;
  }

  .calendar {
    .date-notifier {
      display: none;
    }

    .church,
    .shines,
    .stone,
    .images,
    .byerum,
    .bone {
      span {
        font-weight: normal;
      }

      .bg {
        background-color: #fff;
      }
    }
  }
}
