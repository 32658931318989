@import "src/ui/media.scss";
@import "src/ui/variables.scss";
@import "src/ui/colors.scss";
@import "src/ui/mixins.scss";


$logo-width: 120px;
$flag-width: 108px;


.header-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid $light-grey;
    background-color: $white;
    z-index: 200;

    header {
        height: $header-height;
        @include page-padding();
        max-width: $desktop-plus;
        margin: 0 auto;
        position: relative;
    }

    .logo {
        font-size: 22px;
        width: $logo-width;
        display: inline-block;
        line-height: $header-height;
        vertical-align: top;
    }

    .logo-icon {
        position: relative;
        top: 18px;
        margin: 0 2px 0 0;
    }

    nav {
        width: calc(100% - #{$logo-width});
        text-align: right;
        display: inline-block;
        vertical-align: top;

        .menu-link {
            display: none;
        }

        @include desktop {
            width: calc(100% - #{$logo-width + $flag-width});
            padding: 0 50px 0 0;

            .link {
                padding: 0 20px;
            }

            .mobile-menu-bars {
                display: none;
            }

            .menu-link {
                display: inline-block;
            }
        }

    }

    .link {
        line-height: $header-height;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        display: inline-block;
        position: relative;
        color: $black;

        div {
            transition: transform $timer-standard ease;
            will-change: transform;
            transform: scale(0);
            width: 30px;
            height: 2px;
            position: absolute;
            bottom: 24px;
            left: 50%;
            margin: 0 0 0 -15px;
            background-color: $link;
        }

        &:hover {
            color: $black;
            div {
                transform: scale(1);
            }
        }

        &.active {
            color: $black;
            div {
                transform: scale(1);
            }
        }
    }


    .logo-link {
        color: $black;
        font-weight: 300;
        font-size: 22px;
        display: block;
        text-transform: none;
    }

    .nav-menu {

        display: inline-block;

        @include desktop {
            display: none;
        }
    }

    // .link.shines {
    //     display: none;
    // }


    .languages {
        display: none;

        @include desktop {
            text-align: right;
            width: calc(100% - #{$logo-width});
            display: inline-block;
            text-align: right;
            width: $flag-width;
            vertical-align: top;
        }

        .link {
            margin: 0 0 0 10px;

            div {
                width: 16px;
                margin: 0 0 0 -8px;
            }
        }

        svg {
            line-height: 20px;
            vertical-align: middle;
            margin: -2px 0 0 0;
            transition: filter $timer-standard ease;
            will-change: filter;
            filter: grayscale(100%)brightness(80%);
        }

        .link {
            &:hover {
                svg {
                    filter: grayscale(0)brightness(100%);
                }
            }
        }

        .active {
            svg {
                filter: grayscale(0)brightness(100%);
            }
        }
    }


    .mobile-menu-bars {
        position: absolute;
        height: $header-height;
        width: 30px;
        top: 0;
        right: 16px;
        overflow: hidden;

        @include landscape {
            right: 25px;
        }

    }

    .mobile-menu-bars-wrapper {
        display: inline-block;
        height: $header-height;
        width: 100%;
        transform-origin: center;
        transition: transform $timer-standard ease $timer-standard;
    }

    .mobile-menu-first-bar,
    .mobile-menu-second-bar,
    .mobile-menu-last-bar {
        position: absolute;
        display: inline-block;
        width: 26px;
        height: 2px;
        background-color: $black;
        top: 32px;
        left: 0;
        border-radius: 1px;
    }

    .mobile-menu-first-bar {
        transition: transform $timer-standard ease;
    }

    .mobile-menu-second-bar {
        top: 39px;
        transition: transform $timer-standard ease;
    }

    .mobile-menu-last-bar {
        top: 46px;
        transition: transform $timer-standard ease;
    }
}


.has-open-menu {

    .mobile-menu-first-bar {
        transform: translate3d(0, 7px, 0);
    }

    .mobile-menu-second-bar {
        transform: rotateZ(90deg);
    }

    .mobile-menu-last-bar {
        transform: translate3d(0, -7px, 0);
    }

    .mobile-menu-bars-wrapper {
        transform: rotateZ(45deg);
    }
}
