@import "src/ui/colors.scss";
@import "src/ui/variables.scss";
@import "src/ui/media.scss";

.senses-page {

    margin: 0 auto;
    
    .image-wrapper {
        margin: 0 -16px;
        
        @include landscape {
            margin: 0 -25px;
        }
        
        @include tablet {
            margin: 0;
        }
    }
    
    i {
        max-width: 540px;
        font-size: 16px;
        display: inline-block;
        margin: 0 0 20px 0;
    }
    
    .senses-inner {
        
        max-width: $tablet;
        margin: 40px auto 0 auto;

        div {
            @include tablet {
                display: inline-block;
                width: 50%;
                vertical-align: top;
                padding: 0 40px 0 0;
                
                &:last-child {
                    padding: 0 0 0 40px;
                }
            }
        }
    }

    p {
        margin: 24px 0;
    }
}