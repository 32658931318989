@import "src/ui/colors.scss";
@import "src/ui/media.scss";
@import "src/ui/mixins.scss";

$height: 34px;

.form {

    padding: 16px;
    background-color: #fbfbfb;
    border-top: 1px solid $light-blue;
    border-bottom: 1px solid $light-blue;
    margin: 20px -16px;
    position: relative;
    background-color: $lighter-blue;

    @include tablet {
        margin: 40px 0 80px 0;
        padding: 25px;
        border: 1px solid #eaeaea;
        border: 1px solid $light-blue;
    }

    label {
        display: block;
        margin: 20px 0 30px 0;

        @include tablet {
            span {
                display: inline-block;
                width: 140px;
                vertical-align: top;
                line-height: $height; 
            }

            input,
            textarea {
                display: inline-block;
                width: calc(100% - 140px);
                vertical-align: top;
                margin: 0;
            }
        }
    }

    textarea,
    input {
        height: 34px;
        display: block;
        padding: 10px 16px;
        background-color: $white;
        border: 1px solid #dadada;
        border: 1px solid $light-blue;
        border-radius: 3px;
        margin: 3px 0 0 0;
        width: 100%;
    }

    textarea {
        height: 130px;
    }

    button {
        height: $height;
        line-height: $height; 
        display: block;
        text-align: center;
        color: $white;
        background-color: $link;
        border: 0;
        width: 100%;
        font-weight: bold;
        border-radius: 4px;
        transition: background-color 300ms ease;
        cursor: pointer;

        &:active,
        &:focus,
        &:hover {
            background-color: lighten($link, 10%);
        }
    }

    @include tablet {
        .submit-button-wrapper {
            text-align: right;
        }

        button {
            width: calc(100% - 140px);
            display: inline-block;
        }
    }

    .form-success,
    .form-error {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        text-align: center;
        padding: 16px;
        z-index: 10;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        align-content: center;
        
        
        & > * {
            min-width: 100%;
            flex-basis: 100%;
        }
        
        .form-error-button {
            margin: 60px 0 0 0;
        }

        button {
            
            @include tablet {
                width: 200px;
            }
        }
        
        p {
            font-size: 18px;
            line-height: 1.6;
        }

        strong {
            font-size: 40px;
            font-weight: 400;
            line-height: 1.3;
            margin: 0 0 20px 0;
        }
    }

    .form-success,
    .form-error,
    form {
        transition: opacity 300ms ease;
        opacity: 1;
    }

    .form-success { opacity: 0; }
    .form-error { opacity: 0; }

    &.success,
    &.error,
    &.pending {
        form {
            opacity: 0;
        }
    }

    &.success {
        .form-success { opacity: 1; }
    }

    &.error {
        .form-error { opacity: 1; pointer-events: all; }
    }





}