@import "src/ui/media.scss";
@import "src/ui/variables.scss";
@import "src/ui/colors.scss";
@import "src/ui/mixins.scss";

$cell-height: 28px;
$cell-height-tablet: 32px;
$cell-height-desktop-plus: 36px;

.pictures-additional {
  position: relative;
  padding: 0 0 0 15px;
  display: none;

  .date-notifier {
    left: 0;
    top: 7px;
    border: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }
}

.calendar {
  margin: 50px 0 0 0;

  @include tablet {
    margin: 50px 0 0 0;
  }

  .calendar-tables,
  .calendar-info {
    @include half();
  }

  .calendar-tables {
    width: 100%;

    @include tablet {
      width: 252px;
    }

    @include desktop {
      width: 490px;
    }
  }

  .calendar-info {
    width: 100%;

    @include tablet {
      width: calc(100% - 252px);
      padding: 0 0 0 130px;
    }

    @include desktop {
      width: calc(100% - 490px);
    }
  }

  .stories,
  .cancelled,
  .stone,
  .fossil,
  .shines,
  .images,
  .byerum,
  .church,
  .bone {
    .date {
      display: inline-block;
      width: $cell-height;
      height: $cell-height;
      border-radius: #{$cell-height / 2};
      font-weight: bold;
      position: relative;
      z-index: 20;

      // remove to when adding dates
    }

    .bg {
      width: $cell-height;
      height: $cell-height;
      border-radius: #{$cell-height / 2};
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -#{$cell-height / 2};
      z-index: 10;

      @include tablet {
        margin-left: -#{$cell-height-tablet / 2};
        width: $cell-height-tablet;
        height: $cell-height-tablet;
        border-radius: #{$cell-height-tablet / 2};
      }

      @include desktop-plus {
        margin-left: -#{$cell-height-desktop-plus / 2};
        width: $cell-height-desktop-plus;
        height: $cell-height-desktop-plus;
        border-radius: #{$cell-height-desktop-plus / 2};
      }
    }

    .calendar-type-header {
      .bg {
        left: 12px;
        top: 0;

        @include tablet {
          left: -26px;
        }

        @include desktop-plus {
          left: -30px;
          top: 0;
        }
      }
    }
  }

  .stone .bg {
    // background: linear-gradient(-45deg, #baf7f1, rgb(248 156 156));
    background-color: $red;
  }

  .church .bg {
    background-color: $green;
  }

  .stories .bg,
  .fossil .bg {
    background-color: $blue;
  }

  .shines .bg,
  .images .bg {
    background-color: $purple;
  }
  .byerum .bg {
    background-color: $beige;
  }
  .bone .bg {
    background-color: $red;
  }

  .cancelled .bg {
    background-color: #eaeaea;
  }

  .calendar-type {
    position: relative;
    max-width: 520px;
    padding: 0 0 30px 0;
    margin: 35px 0;

    @include tablet {
      padding: 0 0 30px 0;
    }

    .calendar-type-header {
      padding: 0 0 0 40px;

      @include tablet {
        padding: 0;
      }
    }

    & > p {
      margin: 24px 0 10px 0;
    }

    li {
      margin: 3px 0 0 0;

      .date {
        display: inline-block;
        margin: 0 4px 0 0;
        color: #6b6e71;
      }
    }

    &.last {
      margin: 0;
      border-bottom: 0;
    }

    ul {
      margin: 28px 0 30px 0;
      font-size: $extra-small-font-size;
      margin: 24px 0 30px 0;
      @include landscape {
        font-size: $small-font-size;
      }
    }

    // .purple,
    // .green,
    // .blue,
    // .red {
    //     width: 28px;
    //     height: 28px;
    //     border-radius: 14px;

    //     @include tablet {
    //         width: 36px;
    //         height: 36px;
    //         border-radius: 18px;
    //     }

    // }

    h3 {
      margin: 0 0 10px 0;
      line-height: 1.3;

      @include tablet {
        margin: 0 0 8px 0;
      }

      span {
        font-size: $small-font-size;
        font-weight: 400;
        display: inline-block;

        @include tablet {
          margin: 0 0 0 6px;
        }
      }
    }

    .calendar-type-sub-header {
      margin: 0 0 18px 0;
      display: block;
      line-height: 1.6;

      @include desktop-plus {
        margin: 0 0 26px 0;
        // line-height: 1.8;
      }
    }

    .calendar-type-indicator {
      position: absolute;
      top: 4px;
      left: 0;

      @include tablet {
        left: -54px;
      }
    }
  }

  #map {
    width: 100%;
    height: 0;
    padding: 0 0 120% 0;
    background-color: $pale-grey;
    margin: 20px 0 50px 0;
    border-top: 1px solid $light-grey;

    @include tablet {
      width: calc(100% - 48px);
      padding: 0 0 80% 0;
      margin: 80px 0 20px 0;
    }

    @include desktop {
      padding: 0 0 70% 0;
    }
  }
}

// // remove to when adding dates
// .dates {
//   .stone .bg {
//     background-color: $red;
//     // background-color: transparent;
//   }
//   .fossil .bg {
//     background-color: $blue;
//     // background-color: transparent;
//   }
//   .images .bg {
//     background-color: $purple;
//     // background-color: transparent;
//   }
//   .byerum .bg {
//     background-color: $beige;
//     // background-color: transparent;
//   }
//   .bone .bg {
//     background-color: $red;
//     background-color: transparent;
//   }
// }

.school-link-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;

  @include tablet {
    top: 4px;
    left: -26px;
  }
}

.calendar-type-header {
  .inner-diamond,
  .outer-diamond {
    width: 24px;
    height: 24px;
    margin: 0 0 0 -12px;
    top: 1px;

    @include tablet {
      width: 24px;
      height: 24px;
      margin: 0 0 0 -22px;
    }
  }

  .inner-diamond {
    width: 18px;
    height: 18px;
    margin: 0 0 0 -9px;
    top: 4px;

    @include tablet {
      width: 18px;
      height: 18px;
      margin: 0 0 0 -19px;
    }
  }
}
