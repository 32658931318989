@import 'src/ui/colors.scss';
@import 'src/ui/variables.scss';

a,
.link {
    color: $link;
    padding: 0;
    margin: 0;
    border: 0;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    will-change: color;
    transition: color $timer-standard ease;
    outline: none;

    svg {
        fill: $link;
        will-change: fill;
        transition: fill $timer-standard ease;
    }

    &:visited,
    &:link {
        color: $link;
    }

    &:active,
    &:focus,
    &:hover {
        color: $link-hover;

        svg {
            fill: $link-hover;
        }
    }

    &.disabled {
        color: $indicator-grey;
        cursor: default;
        pointer-events: none;
        &:visited,
        &:link,
        &:hover,
        &:active,
        &:focus {
            color: $indicator-grey;
        }
    }
}
