$header-height: 80px;
$page-padding: 16px;
$page-padding-landscape: 25px;


$timer-short: 150ms;
$timer-standard: 300ms;
$timer-long: 500ms;

$extra-small-font-size: 12px;
$small-font-size: 14px;
$standard-font-size: 16px;
$large-font-size: 18px;
$extra-large-font-size: 20px;
$line-height-small: 1.4;
$line-height-medium: 1.6;
$line-height-standard: 1.8;
