@import "src/ui/colors.scss";
@import "src/ui/mixins.scss";

.contact {

    margin: 40px auto 0 auto;
    max-width: 800px;

    .contact-info {
        margin: 40px 0;
    }

    @include tablet {
        margin: 80px auto 0 auto;
    };

    .image {
        margin: 0 0 16px 0;
        // border: 20px solid $white;
        // box-shadow: 0 0 20px -6px #333;

        @include tablet {
            margin: 0 0 20px 0;
        }
    }

    .image-round {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        overflow: hidden;
        margin: 50px auto 20px auto;

        @include tablet {
            width: 300px;
            height: 300px;
            margin: 0;
        }
    }

    .message {
        @include tablet {
            margin: 80px 0 40px 0;
        }
    }

    .contact-col-image,
    .contact-col-info {
        @include tablet {
            @include half();
        }
    }

    .contact-col-image {
        @include tablet {
            width: 300px;
        }
    }

    .contact-col-info {
        @include tablet {
            width: calc(100% - 300px);
            padding: 10px 0 0 40px;
        }
    }


}
