@import "src/ui/colors.scss";
@import "src/ui/variables.scss";
@import "src/ui/mixins.scss";

.page {
    background-color: $white;
    min-height: 100vh;
    padding: $header-height 0 180px 0;

    .page-view {
        @include page-padding();
        max-width: $desktop-plus;
        margin: 0 auto;
    }

}
