@import "src/ui/variables.scss";
@import "src/ui/colors.scss";

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

dl,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.invisible {
  visibility: hidden;
}

li,
dd,
dt {
  padding: 0;
  margin: 0;
}

.olandsguide-logo {
  display: inline-block;
  vertical-align: top;
  width: 52px;
  margin: 14px 0 0 0;
}

.olinks {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 52px);
  padding: 10px 0 0 14px;

  .link {
    display: inline-block;
    margin: 0 0 6px 0;
  }
}

#curtain {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 75;
  pointer-events: none;
  background-color: $black;
  transition: opacity $timer-standard ease;
  opacity: 0;
  width: 100vw;
  height: 100vh;
}

.has-open-menu #curtain {
  opacity: 0.5;
  pointer-events: all;
}

.text-right {
  text-align: right;
}

.relative {
  position: relative;
}

.hide {
  display: none;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}
