@import "src/ui/media.scss";

.focus-guiding {
  min-height: 200px;
  overflow: hidden;
  margin: 60px 0 40px 0;
  display: none;

  h4 {
    max-width: 340px;
    line-height: 1.5;
  }

  ul {
    margin: 0 0 20px 0;
  }

  @include tablet {
    margin: 100px 0 40px 0;
  }

  strong {
    line-height: 1.5;
    display: block;
  }

  .image {
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 0 20px 0;
  }
}

.home {
  .focus-guiding {
    display: block;
  }
}

.home {
  .calendar-tables {
    .focus-guiding {
      display: none;

      @include tablet {
        display: block;
      }
    }
  }

  .calendar-info {
    .focus-guiding {
      @include tablet {
        display: none;
      }
    }
  }
}

.english,
.german {
  .calendar-tables,
  .calendar-info {
    .focus-guiding {
      display: none;
    }
  }
}
